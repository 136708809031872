
export default class RcTableViewData {

  constructor() {
    this._headerData = [];
    this._tableData = [];
    this._footerData = [];
  }

  addHeaderRow(row) {
    this._headerData.push(row);
    return this;
  }

  addRow(row) {
    this._tableData.push(row);
    return this;
  }

  addFooterRow(row) {
    this._footerData.push(row);
    return this;
  }

  header() {
    return this._headerData;
  }
  
  body() {
    return this._tableData;
  }

  footer() {
    return this._footerData;
  }

  rows() {
    var _rows = [];
    _rows.push(...this._headerData);
    _rows.push(...this._tableData);
    _rows.push(...this._footerData);
    return _rows;
  }
}