import RcTableViewRow from "../cell/common/RcTableViewRow";
import RcTableViewData from "../table/RcTableViewData";
import StringUtils from '@/utils/StringUtils.js';

export default class RcTableViewModel {

  constructor(panel) {
    this._tablePanel = panel;
    this.clearData();
    this.withStateLoading();
    this._showMissingRequirements = false;
  }

  withStateLoading() {
    this.withState("pageLoading");
    return this;
  }

  isStatePageLoading() {
    return this.isState("pageLoading");
  }

  withStateContentLoading() {
    this.withState("contentLoading");
    return this;
  }

  isStateContentLoading() {
    return this.isState("contentLoading");
  }

  withStateContentLoaded() {
    this.withState("contentLoaded");
    return this;
  }

  isStateContentLoaded() {
    return this.isState("contentLoaded");
  }

  withState(state) {
    this._tableState = state;
    return this;
  }

  isState(state) {
    return StringUtils.contains(this._tableState, state);
  }

  panel() {
    return this._tablePanel;
  }

  domain() {
    return this._tablePanel.domain;
  }
  
  state() {
    return this._tableState;
  }

  log() {
    return this.domain().logger();
  }

  start() {
    this.clearAndBuildTableData()
    return this;
  }
  
  showMissingRequirements() {
    return this._showMissingRequirements;
  }

  withValue(valueData) {
    this.log().info("{0}", [JSON.stringify(valueData)]);
    this._showMissingRequirements = false;
  }

  withButtonPressed(buttonData) {
    this.log().info("{0}", [JSON.stringify(buttonData)]);
  }

  clearAndBuildTableData() {
    this
      .clearData()
      .rebuildTableData();
    return this;
  }

  clearData() {
    this._tableData = new RcTableViewData();
    return this;
  }

  rebuildTableData() {
    var tableViewData = new RcTableViewData();
    this
      .buildRowData(tableViewData)
      .endRowData(tableViewData)
      .postBuildRowData(tableViewData)
      .done();

    this._tableData = tableViewData;
    if (this.panel().headerData) {
      this.panel().headerData = this._tableData.header();
    }
    this.panel().viewData = this._tableData.body();
    if (this.panel().footerData) {
      this.panel().footerData = this._tableData.footer();
    }
    this.panel().paint();
  }

  buildRowData(tableData) {
    if (tableData) {
      //
    }
    return this;
  }

  endRowData(tableData) {
    if (tableData) {
      //
    }
    return this;
  }

  postBuildRowData(tableData) {
    if (tableData) {
      //
    }
    return this;
  }

  findRowProgress() {
    return this.findRowByKey("progress");
  }

  rowWithKey(key) {
    return this.findRowByKey(key);
  }
  
  findRowByKey(key) {
    const rows = this._tableData.rows();
    for (var index = 0; index < rows.length; index++) {
      var row = rows[index];
      if (row.key() === key) {
        return row;
      }
      if (row.hasChildren()) {
        row = this.findInChildren(row, key);
        if (row != null) {
          return row;
        }
      }
    }
    return new RcTableViewRow()
  }

  findInChildren(row, key) {
    if (!row.hasChildren()) {
      return null;
    }
    var children = row.children();
    for (var index = 0; index < children.length; index++) {
      var child = children[index];
      if (child.key() === key) {
        return child;
      }
      if (child.hasChildren()) {
        child = this.findInChildren(child, key);
        if (child != null) {
          return child;
        }
      }
    }
    return null;
  }

  findIndexByKey(key) {
    const rows = this._tableData.rows();
    for (var index = 0; index < rows.length; index++) {
      var row = rows[index];
      if (row.key() === key) {
        return index;
      }
    }
    return -1;
  }

  viewData() {
    return this._tableData.body();
  }

  done() {

  }
}