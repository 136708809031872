
import BoxDisplayRow from "../cell/box/BoxDisplayRow";
import RowDisplayRow from "../cell/row/RowDisplayRow";
import SpacerDisplayRow from "../cell/spacer/SpacerDisplayRow";
import LoadingDisplayRow from "@/portals/shared/cell/loading/LoadingDisplayRow";

export default class RcTableViewUtils {
    
  constructor(model, tableData) {
    this._model = model;
    this._tableData = tableData;
  }

  domain() {
    return this.model().domain();
  }
  
  model() {
    return this._model;
  }

  addHeader(row) {
    this._tableData.addHeaderRow(row);
    return this;
  }

  addBody(row) {
    return this.add(row);
  }
  
  add(row) {
    this._tableData.addRow(row);
    return this;
  }
  
  addFooter(row) {
    this._tableData.addFooterRow(row);
    return this;
  }

  start() {
    if (this.model().isStatePageLoading()) {
      this.addPageLoading();
    }
    if (this.model().isStateContentLoading()) {
      this.addContentLoading();
    }
  }

  addPageLoading() {
    this.add(new BoxDisplayRow()
      .withBorder(false)
      .withChild(new RowDisplayRow()
        .withChild(new SpacerDisplayRow().withWidth("5"))
        .withChild(new BoxDisplayRow().withBorder(true).withWidth("5").withChild(new LoadingDisplayRow().withMessage("").withKey("progress")))
        .withChild(new SpacerDisplayRow().withWidth("5"))
      )
    );
  }

  addContentLoading() {
    this.addPageLoading();
  }
}